
import { useContext, useCallback } from 'react'

import { BandContext } from '@utils/hocs/BandProvider'
import BANDS from '@utils/constants/bands'

const useBandNav = () => {
  const { band, onChangeBand } = useContext(BandContext);

  const onBandNav = useCallback((value) => {
    if (value === 'EXPOBAND') {
      onChangeBand(BANDS.EXPO);
      return;
    }

    if (value === 'FANBAND') {
      onChangeBand(BANDS.FAN)
      return;
    }
  }, [onChangeBand])

  const onBand = useCallback((value) => {
    onChangeBand(value);
  }, [onChangeBand])

  return {
    band,
    onBand,
    onBandNav
  }
};

export default useBandNav;