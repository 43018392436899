
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  Divider,
  Button
} from '@material-ui/core'

import BackArrowIcon from '@components/Icons/BackArrowIcon'
import NAVBAR_MENUS from '@utils/constants/navbar-menus'
import useBandNav from '@utils/hooks/useBandNav'

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.custom.layout.drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: theme.custom.layout.topAppBarHeight,
    justifyContent: 'flex-start',
  },
  item: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
    width: '100%'
  }
}));

const SideMenuDrawer = ({
  open,
  setOpen,
}) => {
  const classes = useStyles();
  const { onBandNav } = useBandNav();

  const navHandler = (value) => () => {
    onBandNav(value)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => setOpen(false)}>
          <BackArrowIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {
          NAVBAR_MENUS.map((item, index) =>
            <ListItem key={index}>
              <ListItemText
                primary={
                  <Button
                    href={item.HREF}
                    className={classes.item}
                    onClick={navHandler(item.LABEL)}
                  >
                    {item.LABEL}
                  </Button>
                }
              />
            </ListItem>
          )
        }
      </List>
    </Drawer>
  );
}

export default memo(SideMenuDrawer)