
import LOGO_IMAGE_PATH from '@images/logo.png';
import HERO_SECTION_BACKGROUND_IMAGE_PATH from '@images/background/hero-section.jpg';
import EXPO_BAND_BACKGROUND_IMAGE_PATH from '@images/background/expo-band.jpg';
import FAN_BAND_BACKGROUND_IMAGE_PATH from '@images/background/fan-band.jpg';
import ARM_ICON_IMAGE_PATH from '@images/icon/fanband-hand.png';
import BAND_WIDE_ICON_IMAGE_PATH from '@images/icon/band-wide.png';
import BAND_ANIMATION_ICON_IMAGE_PATH from '@images/icon/band-animation.gif';
import WHITE_BAND_ICON_IMAGE_PATH from '@images/icon/white-band.png';
import BLUE_BAND_ICON_IMAGE_PATH from '@images/icon/blue-band.png';

export {
  LOGO_IMAGE_PATH,
  HERO_SECTION_BACKGROUND_IMAGE_PATH,
  EXPO_BAND_BACKGROUND_IMAGE_PATH,
  FAN_BAND_BACKGROUND_IMAGE_PATH,
  ARM_ICON_IMAGE_PATH,
  BAND_WIDE_ICON_IMAGE_PATH,
  BAND_ANIMATION_ICON_IMAGE_PATH,
  WHITE_BAND_ICON_IMAGE_PATH,
  BLUE_BAND_ICON_IMAGE_PATH
};