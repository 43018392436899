
const NAVBAR_MENUS = [
  {
    LABEL: 'EXPOBAND',
    HREF: '/#band'
  },
  {
    LABEL: 'FANBAND',
    HREF: '/#band'
  },
  {
    LABEL: 'WHAT IT IS',
    HREF: '/#what-it-is'
  },
  {
    LABEL: 'FEATURES',
    HREF: '/#features'
  },
  {
    LABEL: 'CONNECT',
    HREF: '/#connect'
  }
]

export default NAVBAR_MENUS