
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import SOCIALS from '@utils/constants/social'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    color: theme.palette.text.primary
  }
}));

const LinkedInIcon = ({
  className,
  viewBox,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <a aria-label={SOCIALS.LINKED_IN.LABEL} href={SOCIALS.LINKED_IN.HREF} target='_blank' rel='noreferrer'>
      <SvgIcon viewBox={viewBox || '0 0 32 32'} {...rest} className={clsx(classes.root, className)}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Experiwear-showing-Expoband" transform="translate(-827.000000, -5945.000000)">
            <g id="Group-5" transform="translate(581.000000, 5945.000000)">
              <g id="brandico:linkedinRect" transform="translate(246.000000, 0.000000)">
                <rect id="ViewBox" x="0" y="0" width="32" height="32"></rect>
                <path d="M6.27080434,0 C2.82235337,0 0,2.82228934 0,6.27080434 L0,25.7302201 C0,29.1786711 2.82228934,32 6.27080434,32 L25.7302201,32 C29.1787031,32 32,29.1787031 32,25.7302201 L32,6.27080434 C32,2.82235337 29.1787031,0 25.7302201,0 L6.27080434,0 Z M7.84800558,5.28064364 C9.50146414,5.28064364 10.5199252,6.36610999 10.5513629,7.79294152 C10.5513629,9.18827127 9.50140011,10.304247 7.8160236,10.304247 L7.78500205,10.304247 C6.16301324,10.304247 5.1146511,9.1883353 5.1146511,7.79294152 C5.1146511,6.366142 6.1947391,5.28064364 7.84797356,5.28064364 L7.84800558,5.28064364 Z M22.0967923,11.9494459 C25.2767099,11.9494459 27.6605036,14.0278902 27.6605036,18.49429 L27.6605036,26.8323017 L22.8278958,26.8323017 L22.8278958,19.0533183 C22.8278958,17.0985441 22.1284861,15.7649053 20.3795939,15.7649053 C19.0444824,15.7649053 18.2486787,16.6637941 17.899278,17.5321415 C17.7716382,17.8428053 17.7402645,18.2766909 17.7402645,18.7112808 L17.7402645,26.8323017 L12.9076566,26.8323017 C12.9076566,26.8323017 12.9710764,13.6549272 12.9076566,12.2904909 L17.7412569,12.2904909 L17.7412569,14.3497588 C18.3834576,13.3589259 19.5321836,11.9494459 22.0967923,11.9494459 L22.0967923,11.9494459 Z M5.39970367,12.2915474 L10.2323115,12.2915474 L10.2323115,26.8323657 L5.39970367,26.8323657 L5.39970367,12.2915474 L5.39970367,12.2915474 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </a>
  )
}

export default LinkedInIcon;