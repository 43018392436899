
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import FooterNavbar from './FooterNavbar'
import Socials from './Socials'
import useCommonStyles from '@styles/use-styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.custom.palette.blue,
    padding: theme.spacing(5, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  copyright: {
    fontSize: 20,
    opacity: 0.5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <section className={classes.root}>
      <div className={clsx(classes.container, commonClasses.containerWidth)}>
        <FooterNavbar />
        <Socials />
        <Typography variant='h5' className={classes.copyright}>
          Copyright © 2024. Experiwear Inc. All rights reserved.
        </Typography>
      </div>
    </section>
  );
};

export default memo(Footer);