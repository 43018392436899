
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5)
  }
}));

const MenuIcon = ({
  className,
  viewBox,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox={viewBox || '0 0 20 14'} {...rest} className={clsx(classes.root, className)}>
      <g id="Group_3" data-name="Group 3" transform="translate(1)">
        <path id="Path" d="M0,.5H18" transform="translate(0 6.5)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path-2" data-name="Path" d="M0,.5H18" transform="translate(0 0.5)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path-3" data-name="Path" d="M0,.5H18" transform="translate(0 12.5)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
      </g>
    </SvgIcon>
  )
}

export default MenuIcon;
