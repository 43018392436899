
import React, { memo, useState } from 'react'
import {
  Hidden,
  IconButton
} from '@material-ui/core'

import MenuIcon from '@components/Icons/MenuIcon'
import SideMenuDrawer from './SideMenuDrawer'

const MenuIconButton = () => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev)
  };

  return (
    <>
      <Hidden mdUp>
        <IconButton
          edge='start'
          aria-label='menu'
          onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        {
          open &&
          <SideMenuDrawer
            open={open}
            setOpen={setOpen}
          />
        }
      </Hidden>
    </>
  );
};

export default memo(MenuIconButton);