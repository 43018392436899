
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"

import FOOTER_MENUS from '@utils/constants/footer-menus'
import useBandNav from '@utils/hooks/useBandNav'

const useStyles = makeStyles(theme => ({
  navbar: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'wrap',
    marginBottom: theme.spacing(5)
  },
  item: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: theme.spacing(1, 3),
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textDecoration: 'unset',
    [theme.breakpoints.down('xs')]: {
      flex: 'auto'
    }
  },
}));

const FooterNavbar = () => {
  const classes = useStyles();
  const { onBandNav } = useBandNav();

  const navHandler = (value) => () => {
    onBandNav(value)
  }

  return (
    <div className={classes.navbar}>
      {
        FOOTER_MENUS.map((item, index) => (
          <Link
            key={index}
            to={item.HREF}
            className={classes.item}
            onClick={navHandler(item.LABEL)}
          >
            {item.LABEL}
          </Link>
        ))
      }
    </div>
  );
};

export default memo(FooterNavbar);