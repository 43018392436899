
const LINKS = Object.freeze({
  HOME: {
    TITLE: 'Home',
    HREF: '/',
  },
  PRIVACY: {
    TITLE: 'Privacy',
    HREF: '/privacy',
  },
  REMOVE: {
    TITLE: 'Remove',
    HREF: '/remove',
  },
});

export default LINKS;
