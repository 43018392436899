
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'gatsby'

import { LOGO_IMAGE_PATH } from '@utils/constants/image-paths'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textDecoration: 'unset',
    color: theme.palette.text.primary
  },
  picture: {
    display: 'flex'
  },
  img: {
    width: 32,
    height: 32
  },
  logo: {
    marginLeft: theme.spacing(1.5),
    fontWeight: 'bold',
    fontSize: 22
  }
}));

const Logo = ({
  className,
  ...rest
}) => {

  const classes = useStyles();

  return (
    <Link to='/' className={classes.root}>
      <picture className={clsx(classes.picture, className)} {...rest}>
        <source srcSet={LOGO_IMAGE_PATH} />
        <img
          className={classes.img}
          src={LOGO_IMAGE_PATH}
          alt='experiwear logo' />
      </picture>
      <Typography className={classes.logo}>
        EXPERIWEAR
      </Typography>
    </Link>
  )
}

export default memo(Logo);
