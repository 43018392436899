
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(2.75)
  }
}));

const BackArrowIcon = ({
  className,
  viewBox,
  color = '#000000',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox={viewBox || '0 0 6 10'} {...rest} className={clsx(classes.root, className)}>
      <title>Path</title>
      <g id='Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Desktop-My-Chats' transform='translate(-1268.000000, -364.000000)' fill={color} fillRule='nonzero'>
          <g id='Group-2' transform='translate(164.000000, 317.000000)'>
            <g id='chevron' transform='translate(1103.142857, 47.000000)'>
              <path d='M4.84962854,4.9988835 L4.84962854,4.9988835 L4.84962854,4.9988835 L0.929553871,1.111221 C0.831083096,1.01520176 0.833427638,0.856658374 0.934242956,0.758406133 L1.63526109,0.0751064634 C1.73607641,-0.0231457767 1.90019437,-0.0253787822 1.99866514,0.0706404525 L6.78622045,4.82024306 C6.83780038,4.86936918 6.86124581,4.93635934 6.85655672,5.0011165 C6.85890126,5.06810667 6.83545584,5.13286382 6.78622045,5.18198994 L1.99866514,9.92935955 C1.90019437,10.0253788 1.73607641,10.0231458 1.63526109,9.92489354 L0.934242956,9.24159387 C0.833427638,9.14334163 0.831083096,8.98479824 0.929553871,8.888779 L4.84962854,4.9988835 Z' id='Path'></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default BackArrowIcon;
