
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import SEO from './SEO'
import Content from './Content'
import ToastProvider from '@utils/hocs/ToastProvider'
import { BandProvider } from '@utils/hocs/BandProvider'
import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ToastProvider />
    <BandProvider>
      <GlobalStyles />
      <CssBaseline />
      <SEO />
      <Content>
        {children}
      </Content>
    </BandProvider>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Layout);
