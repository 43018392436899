
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import Navbar from '../Navbar'
import Footer from '../Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  container: {
    flex: '1 0 auto'
  }
}));

const Content = ({ children }) => {
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Navbar />
      <div className={classes.container}>
        {children}
      </div>
      <Footer />
    </main>
  )
};


export default memo(Content);
