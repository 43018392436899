
import LINKS from '@utils/constants/links';

const FOOTER_MENUS = [
  {
    LABEL: 'EXPOBAND',
    HREF: '/#band'
  },
  {
    LABEL: 'FANBAND',
    HREF: '/#band'
  },
  {
    LABEL: 'WHAT IT IS',
    HREF: '/#what-it-is'
  },
  {
    LABEL: 'FEATURES',
    HREF: '/#features'
  },
  {
    LABEL: 'CONNECT',
    HREF: '/#connect'
  },
  {
    LABEL: LINKS.PRIVACY.TITLE,
    HREF: LINKS.PRIVACY.HREF,
  }
]

export default FOOTER_MENUS