
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Hidden,
  Button
} from '@material-ui/core'

import NAVBAR_MENUS from '@utils/constants/navbar-menus'
import useBandNav from '@utils/hooks/useBandNav'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  item: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: theme.spacing(1, 3),
    color: theme.palette.text.primary
  }
}));

const NavBarMenu = () => {
  const classes = useStyles();
  const { onBandNav } = useBandNav();

  const navHandler = (value) => () => {
    onBandNav(value)
  }

  return (
    <Hidden smDown>
      <div className={classes.root}>
        {
          NAVBAR_MENUS.map((item, index) => (
            <Button
              key={index}
              href={item.HREF}
              className={classes.item}
              onClick={navHandler(item.LABEL)}
            >
              {item.LABEL}
            </Button>
          ))
        }
      </div>
    </Hidden>
  );
};

export default memo(NavBarMenu);