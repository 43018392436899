
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import InstagramIcon from '@components/Icons/InstagramIcon'
import TwitterIcon from '@components/Icons/TwitterIcon'
import FacebookIcon from '@components/Icons/FacebookIcon'
import LinkedInIcon from '@components/Icons/LinkedInIcon'

const useStyles = makeStyles(theme => ({
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5)
  },
  socialIcon: {
    margin: theme.spacing(0, 2)
  }
}));

const Socials = () => {
  const classes = useStyles();

  return (
    <div className={classes.socialContainer}>
      <TwitterIcon className={classes.socialIcon} />
      <InstagramIcon className={classes.socialIcon} />
      <FacebookIcon className={classes.socialIcon} />
      <LinkedInIcon className={classes.socialIcon} />
    </div>
  );
};

export default memo(Socials);