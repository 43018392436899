
const SOCIALS = Object.freeze({
  FACEBOOK: {
    HREF: 'https://www.facebook.com/Experiwear-107400751419222',
    LABEL: 'facebook link'
  },
  TWITTER: {
    HREF: 'https://twitter.com/experiwear/',
    LABEL: 'twitter link'
  },
  INSTAGRAM: {
    HREF: 'https://www.instagram.com/experiwear/',
    LABEL: 'instagram link'
  },
  LINKED_IN: {
    HREF: 'https://www.linkedin.com/company/experiwear/',
    LABEL: 'linkedin link'
  },
  GOOGLE_PLAY_SERVICES: {
    HREF: 'https://www.google.com/policies/privacy/',
    LABEL: 'Google Play Services'
  }
})

export default SOCIALS;
