import React, { useState, createContext } from 'react';

import BANDS from '@utils/constants/bands'

const BandContext = createContext({
  band: BANDS.EXPO,
  onChangeBand: () => { }
});

const BandContextConsumer = BandContext.Consumer;

const BandProvider = ({
  children
}) => {
  const [band, setBand] = useState(BANDS.EXPO);

  const onChangeBand = (value) => {
    setBand(value)
  }

  const value = {
    band: band,
    onChangeBand: onChangeBand
  };

  return (
    <BandContext.Provider value={value}>
      {children}
    </BandContext.Provider>
  );
};

export { BandProvider, BandContext, BandContextConsumer }