import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    padding: 0 !important;
  }
`;

export default GlobalStyles;
