
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import SOCIALS from '@utils/constants/social'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    color: theme.palette.text.primary
  }
}));

const FacebookIcon = ({
  className,
  viewBox,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <a aria-label={SOCIALS.FACEBOOK.LABEL} href={SOCIALS.FACEBOOK.HREF} target='_blank' rel='noreferrer'>
      <SvgIcon viewBox={viewBox || '0 0 96 96'} {...rest} className={clsx(classes.root, className)}>
        <path d="M72.089.02L59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037a1.96 1.96 0 00-1.96 1.961v15.803a1.96 1.96 0 001.96 1.96H36.57v39.876a1.96 1.96 0 001.96 1.96h16.352a1.96 1.96 0 001.96-1.96V54.287h14.654a1.96 1.96 0 001.96-1.96l.006-15.803a1.963 1.963 0 00-1.961-1.961H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003a1.96 1.96 0 001.959-1.96V1.98A1.96 1.96 0 0072.089.02z" />
      </SvgIcon>
    </a>
  )
}

export default FacebookIcon;